<template>
  <div class="main-container">
    <h5 class="main-title">표준화 F&A</h5>
    <div class="search-container">
      <div class="bs-flex">
        <h5 class="search-title">게시물 검색</h5>
        <mdb-select
            outline
            v-model="oneOptions"
            @getValue="getSelectOneValue"
            class="search-select"/>
        <mdb-select
            v-if="oneOptionVal === '상지'"
            outline
            v-model="ueOptions"
            @getValue="getSelectUlValue"
            class="search-select"/>
        <mdb-select
            v-if="oneOptionVal === '하지'"
            outline
            v-model="leOptions"
            @getValue="getSelectUlValue"
            class="search-select"/>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="search-btn" @click="dataInit">
          검색
        </button>
      </div>
    </div>
    <div class="sub-container">
      <button class="add-btn" @click="addBoard">
        게시글 등록
      </button>
    </div>
    <div class="table-container">
      <mdb-datatable-2
          v-model="data"
          class="text-center"
          borderless
          hover
          :searching="{value: search, field: fieldName} "
          @selected="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </div>
    <div class="sub-container">
      <p>{{ detailTitle }}</p>
    </div>
    <div class="result-null-container" v-if="isDataNull">
      <img src="@/assets/images/IA/img_null.png" alt="null">
      <h5>조회할 게시글을 선택해주세요.</h5>
    </div>
    <BoardFnaForm :selData="selData" v-else @refresh="refresh"/>
  </div>
</template>

<script>
import {mdbDatatable2, mdbSelect} from "mdbvue";
import fna_columns from "@/assets/data/master/border/fna_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import BoardFnaForm from "@/views/master/board/fna/BoardFnaForm";

export default {
  name: "BoardFna",
  components: {
    BoardFnaForm,
    mdbSelect,
    mdbDatatable2,
  },
  data() {
    return {
      fbCollection: 'f&a',
      data: {
        columns: fna_columns,
        rows: []
      },
      oneOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
      ],
      ueOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM", value: "Fugl-Meyer"},
        {text: "BB", value: "BB"},
        {text: "WMFT", value: "WMFT"},
        {text: "AAUT", value: "AAUT"},
        {text: "MAL", value: "MAL"},
        {text: "SIS", value: "SIS"},
      ],
      leOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM_LOW", value: "Fugl-Meyer-Low"},
        {text: "BBS", value: "BBS"},
        {text: "TUG", value: "TUG"},
        {text: "FTSTS", value: "FTSTS"},
        {text: "TMWT", value: "TMWT"},
        {text: "SMWT", value: "SMWT"},
        {text: "DGI", value: "DGI"},
        {text: "ABCscale", value: "ABCscale"},
      ],
      oneOptionVal: '전체',
      ulOptionVal: '전체',
      search: '',
      fieldName: '',
      isDataNull: true,
      detailTitle: '게시글',
      selData: {},
    }
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      const self = this;
      self.data.rows.splice(0);
      const db = firebase.firestore();
      let orderNumber = 0;
      let bsRef = db.collection(self.fbCollection);

      if (self.oneOptionVal !== '전체') {
        bsRef = bsRef
            .where('ul', '==', self.oneOptionVal)
      }

      if (self.ulOptionVal !== '전체') {
        bsRef = bsRef
            .where('toolName', '==', self.ulOptionVal)
      }

      bsRef
          .orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          orderNumber = querySnapshot.size
          querySnapshot.forEach((doc) => {
            const _data = doc.data();
            _data["docId"] = doc.id;
            _data.orderNumber = orderNumber--;
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            self.data.rows.push(_data);
          });
        }
      })
    },
    getSelectOneValue(value) {
      this.oneOptionVal = value;
      if (value === '전체') {
        this.ulOptionVal = value;
      }
    },
    getSelectUlValue(value) {
      this.ulOptionVal = value;
    },
    onSelected(event) {
      const self = this;
      if(!event){
        self.detailTitle = '게시글'
        self.isDataNull = true;
        return
      }

      self.isDataNull = false;
      self.selData = JSON.parse(JSON.stringify(event))
      self.detailTitle = event.title;
    },
    addBoard() {
      this.$router.push('/master/fna/add')
    },
    refresh() {
      const self = this;
      self.dataInit();
      self.detailTitle = '게시글'
      self.isDataNull = true;
    }
  }
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
  min-width: 890px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.bs-flex {
  display: flex;
  align-items: center;
}

.search-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 8px 0 0;
  min-width: 100px;
}

.search-select {
  margin-right: 8px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .md-form {
  width: 151px;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.search-input {
  margin-right: 10px;
  padding-left: 30px;
  background: #FFFFFF;
  font-size: 16px;
}

.icon-input {
  width: 100%;
  position: relative;
  margin-right: 8px;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.sub-container {
  display: flex;
  flex-direction: row;
}

.sub-container p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-top: 26px;
  margin-bottom: 18px;

}

.search-btn {
  min-width: 108px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
}

.add-btn {
  width: 140px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  margin: 18px 0 10px auto;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}


.result-null-container {
  margin-top: 15px;
  width: 100%;
  height: 276px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-null-container img {
  width: 101px;
  margin-bottom: 16px;
}

.result-null-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}

/deep/ td {
  cursor: pointer;
}
</style>
