<template>
  <div class="form-container">
    <div class="bs-flex">
      <h5>상/하지</h5>
      <input class="custom-input-box dis w237" disabled v-if="isEdit" v-model="selData.ul"/>
      <mdb-select
          v-else
          outline
          v-model="oneOptions"
          @getValue="getSelectOneValue"
          class="search-select"/>
      <h5 class="ml20">평가도구 선택</h5>
      <input class="custom-input-box dis w237" disabled v-if="isEdit" v-model="selData.toolName"/>
      <mdb-select
          v-if="oneOptionVal === '상지' && !isEdit"
          outline
          v-model="ueOptions"
          @getValue="getSelectUlValue"
          class="search-select"/>
      <mdb-select
          v-if="oneOptionVal === '하지' && !isEdit"
          outline
          v-model="leOptions"
          @getValue="getSelectUlValue"
          class="search-select"/>
    </div>
    <div class="bs-flex">
      <h5>게시글 제목</h5>
      <input class="custom-input-box dis" readonly v-if="isEdit" v-model="selData.title"/>
      <input class="custom-input-box" v-else v-model="selData.title"/>
    </div>
    <div class="bs-flex">
      <h5>게시글 내용</h5>
      <textarea class="custom-textarea-box dis" cols="20" readonly v-if="isEdit" v-model="selData.desc"/>
      <textarea class="custom-textarea-box" cols="20" v-else v-model="selData.desc"/>
    </div>
    <hr>
    <div class="btn-flex" v-if="isEdit">
      <button class="delete-btn" @click="deleteBoard">
        삭제
      </button>
      <button class="edit-btn" @click="showEdit">
        수정
      </button>
    </div>
    <div class="btn-flex" v-else>
      <button class="btn-cancel" @click="isEdit = true">취소</button>
      <button class="complete-btn" @click="updateBoard">
        완료
      </button>
    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/lib/firebaseError";

export default {
  name: "BoardFnaForm",
  components: {
    mdbSelect,
  },
  props: {
    selData: Object,
  },
  data() {
    return {
      fbCollection: 'f&a',
      isEdit: true,
      oneOptions: [
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
      ],
      ueOptions: [
        {text: "FM", value: "Fugl-Meyer"},
        {text: "BB", value: "BB"},
        {text: "WMFT", value: "WMFT"},
        {text: "AAUT", value: "AAUT"},
        {text: "MAL", value: "MAL"},
        {text: "SIS", value: "SIS"},
      ],
      leOptions: [
        {text: "FM_LOW", value: "Fugl-Meyer-Low"},
        {text: "BBS", value: "BBS"},
        {text: "TUG", value: "TUG"},
        {text: "FTSTS", value: "FTSTS"},
        {text: "TMWT", value: "TMWT"},
        {text: "SMWT", value: "SMWT"},
        {text: "DGI", value: "DGI"},
        {text: "ABCscale", value: "ABCscale"},
      ],
      oneOptionVal: '상지',
      ulFormOptionVal: 'Fugl-Meyer',
      tempData: {},
    }
  },
  watch: {
    // ulFormOptionVal(a,b){
    //   console.log(a,b)
    // }
  },
  methods: {
    getSelectOneValue(value) {
      this.oneOptionVal = value;
    },
    getSelectUlValue(value) {
      this.ulFormOptionVal = value;
    },
    showEdit() {
      const self = this;
      self.isEdit = false
      self.tempData = JSON.parse(JSON.stringify(self.selData))
      // console.log(self.selData)
      // console.log(self.ulFormOptionVal)

      this.compareSelect()
    },
    compareSelect() {
      const self = this

      let index = self.oneOptions.findIndex((element) => element.value === self.tempData.ul);
      self.oneOptions[index].selected = true
      self.oneOptions.push()

      if (self.tempData.ul === '상지') {
        index = self.ueOptions.findIndex((element) => element.value === self.tempData.toolName);
        self.ueOptions[index].selected = true
        self.leOptions[0].selected = true
        self.ueOptions.push()
        self.oneOptionVal = '상지'
        self.ulFormOptionVal = 'Fugl-Meyer'
        // console.log(self.ulFormOptionVal)
      }
      if (self.tempData.ul === '하지') {
        index = self.leOptions.findIndex((element) => element.value === self.tempData.toolName);
        self.leOptions[index].selected = true
        self.ueOptions[0].selected = true
        self.leOptions.push()
        self.oneOptionVal = '하지'
        self.ulFormOptionVal = 'Fugl-Meyer-Low'
        // console.log(self.ulFormOptionVal)
      }
    },
    deleteBoard() {
      const self = this;
      const docRef = firebase.firestore().collection(self.fbCollection);
      docRef.doc(self.selData.docId).delete().then(() => {
        alert('삭제 되었습니다.')
        self.$emit('refresh', 0)
      })
    },
    updateBoard() {
      const self = this;
      const data = {
        desc: self.selData.desc,
        title: self.selData.title,
        toolName: self.ulFormOptionVal,
        ul: self.oneOptionVal,
      }
      firebase.firestore().collection(self.fbCollection)
          .doc(self.selData.docId)
          .update(data)
          .then(async () => {
            alert('수정 되었습니다.');
            self.$emit('refresh', 0)
          }).catch((err) => {
        firebaseError(err)
      });
    }
  }
}
</script>

<style scoped>
.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.bs-flex {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  min-width: 150px;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.dis {
  background: #F8F8FA !important;
}

.btn-flex {
  display: flex;
}

.custom-textarea-box {
  width: 100%;
  min-height: 180px;
  border-radius: 4px;
  border: 1px solid #E1E3E6 !important;
}

.delete-btn {
  width: 66px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
  margin-right: 10px;
}

.edit-btn {
  width: 66px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.complete-btn {
  width: 66px;
  height: 40px;
  background: #000000;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.w237 {
  width: 237px;
}

.ml20 {
  margin-left: 20px !important;
}

.btn-cancel {
  width: 66px;
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  height: 40px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

</style>
